<template>
  <ListReservationCompletedUFinal></ListReservationCompletedUFinal>
</template>

<script>

import ListReservationCompletedUFinal from "@core/layouts/components/admin-components/ListReservationCompletedUFinal";

export default {
  name: "ReservationCompleted",

  components: {
    ListReservationCompletedUFinal,
  }
}
</script>

<style scoped>

</style>